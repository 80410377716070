import Swal from "sweetalert2";

const DataAlert = async (data) => {
    const invalidFields = []
    const testInfo = {
        Quimica: data.material || "",
        Ciclos: data.cycles || "",
        Autonomía: data.autonomy || "",
        Energía_nominal: data.nominal_energy || "",
        Tiempo_carga_1: data.charge_time_1 || 0,
        Tiempo_carga_2: data.charge_time_2 || 0,
        Precio: data.price || "",
        Peso_bruto_vehicular: data.gvwr || "",
      };

      Object.entries(testInfo).forEach(([key, value]) => {
        if (value === "" || value === null) {
            invalidFields.push(key);
        }
    });

    if (Object.keys(data).length === 0) {
        return false;
    } else {
        if (Object.values(testInfo).some(value => value === "" || value === null)) {
            Swal.fire({
                title: "Datos Faltantes",
                text: `Los siguientes campos no están completos o son nulos: ${invalidFields.join(", ")}`,
                icon: "error"
            });
        }
    }
}

export default DataAlert