import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from '../../images/logo/auteco-logo.png'
import TitleComponent from '../../components/titleComponent/TitleComponent';

import './download.css';
import { Height, Phishing } from '@mui/icons-material';
// import '../../styles/global.css';

const DownloaderModal = () => {
    const [yearsUse, setYearsUse] = useState()
    const [annualUse, setAnnualUse] = useState()

    const [name, setName] = useState('');
    const [customer, setCustomer] = useState('');
    const [email, setEmail] = useState('');
    const [cel, setCel] = useState('');
    const handleName = (e) => setName(e.target.value);
    const handleCustomer = (e) => setCustomer(e.target.value);
    const handleEmail = (e) => setEmail(e.target.value);
    const handleCel = (e) => setCel(e.target.value);
    const [clonedResult, setClonedResult] = useState('');

    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
    };

    useEffect(() => {
        //get params to set in the inputs
        const storedParams = JSON.parse(localStorage.getItem("dataParams"));
        if (storedParams) {
            setYearsUse((storedParams.yearsUse).toLocaleString(undefined, options).replace(/,/g, '.'));
            setAnnualUse((storedParams.annualUse).toLocaleString(undefined, options).replace(/,/g, '.'));
        }
    }, []);


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = () => {
        const clonedResult = document.getElementById('downloader-sec').innerHTML;
        setClonedResult(clonedResult);
        setShow(true);
        const elementsWithClass = document.querySelectorAll('.highcharts-number');
        elementsWithClass.forEach(element => {
            let number = parseFloat(element.textContent);
            let formattedNumber = number.toLocaleString('es-ES', options);
            element.textContent = formattedNumber;
        });
    };

    const getCurrentDateTime = () => {
        const currentDate = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return currentDate.toLocaleDateString('es-ES', options);
    };

    const handleDownload = () => {
        const capture = document.querySelector('.container-PDF');
        html2canvas(capture).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const width = 300
            const height = 300
            const pdf = new jsPDF('p', 'mm', 'a3');
            pdf.addImage(imgData, 'PNG', 0, 0, width, height);
            pdf.save('Resultados Auteco.pdf');
        });
    };


    return (
        <>
            <button onClick={handleShow} className='shadow-left'>Generar PDF</button>
            <Modal size="lg" className='modal-results' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Descargable</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-pdf'>
                    <div className="row">
                        <div class="col-lg-3">
                            <label for="yearsUse" class="col-form-label modal-lavel">
                                Nombre del asesor
                            </label>
                            <input
                                type="text"
                                className='form-control'
                                class="form-control"
                                onChange={handleName}
                                value={name}
                            />
                        </div>
                        <div class="col-lg-3">
                            <label for="annualUse" class="col-form-label modal-lavel">
                                Nombre del cliente
                            </label>
                            <input
                                type="text"
                                className='form-control'
                                class="form-control"
                                onChange={handleCustomer}
                                value={customer}
                            />
                        </div>
                        <div class="col-lg-3">
                            <label for="yearsUse" class="col-form-label modal-lavel">
                                Correo del asesor
                            </label>
                            <input
                                type="text"
                                className='form-control'
                                class="form-control"
                                onChange={handleEmail}
                                value={email}
                            />
                        </div>
                        <div class="col-lg-3">
                            <label for="yearsUse" class="col-form-label modal-lavel">
                                Celular del asesor
                            </label>
                            <input
                                type="text"
                                className='form-control'
                                class="form-control"
                                onChange={handleCel}
                                value={cel}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="container-PDF">
                        <div className="downloader-header">
                            <p className='date bold-title'>Fecha {getCurrentDateTime()}</p>
                            <div className="logo">
                                <img src={logo} width={120} />
                            </div>
                        </div>
                        <br /><br />
                        <TitleComponent text={'Resultados TCO'} />
                        <div className="row">
                            <div className="col-sm-6">
                                <p className='bold-title'>Gerente comercial </p>
                                <p className='text'>Nombre: {name}</p>
                                <p className='text'>Email: {email}</p>
                                <p className='text'>Celular: {cel}</p>
                                <br />
                                <p className='bold-title'>Cliente</p>
                                <p className='text'>{customer}</p>
                                <br />
                            </div>
                            <div className="col-sm-6">
                                <p className='subtitle' >Parámetros</p>
                                <p className='text'>Kilómetros al año</p>
                                <p className="bold-title">
                                    {annualUse}
                                </p>
                                <p className='text'>Años de uso</p>
                                <p className="bold-title">
                                    {yearsUse}
                                </p>
                            </div>
                        </div>
                        <br />
                        <hr />
                        <br />
                        <div className="cloned-sec" dangerouslySetInnerHTML={{ __html: clonedResult }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={handleDownload}>
                        Descargar PDF
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DownloaderModal;
