import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../config";
import '../../styles/quoter.css'
import TableNamesQuoter from "./components/TableNamesQupter";
import GetDataDetail from "../../services/loaderService";
import SelectQuoter from "./components/selectQuoter/SelectQuoter";
import Spinner from "../../components/spinner/Spinner";
import TitleComponent from "../../components/titleComponent/TitleComponent";

const domain = config.api_domain.domain

const Quoter = () => {
  const params = useParams();
  const type = params.type;
  const url = `${domain}/features`;

  const [cleanDataDetail, setCleanDataDetail] = useState([]);
  const [cleanDataDetail2, setCleanDataDetail2] = useState([]);
  const [position, setPosition] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleDatas = async () => {
    await GetDataDetail(setCleanDataDetail, url, params.id);
    await GetDataDetail(setCleanDataDetail2, url, position)
  }

  useEffect(() => {
    handleDatas()
    setLoading(false)
  }, [url, params.id, position]);

  const handleChange = (event) => {
    setPosition(event.target.value);

  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <TitleComponent text={"Comparativo general"} />
        <div className="container">
          <SelectQuoter value={position} onChange={handleChange} type={type} />
            <div>
              <TableNamesQuoter
                datas={cleanDataDetail[0]}
                datas2={cleanDataDetail2[0]}
              />
            </div>
        </div>
    </div>
  );
};

export default Quoter;
