// import axios from 'axios';
import config from '../config';
import axios from 'axios';

const domain = config.api_domain.domain


export const LoaderCategories = async () => {
  try {
    const region = localStorage.getItem('region')
    const response = await axios.get(`${domain}/admin/active_categories/${region}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default LoaderCategories
