import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import axios from 'axios';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import TableCharger from './components/TableCharger'
import config from '../../config';

const domain = config.api_domain.domain

const BateryCharger = () => {
    const params = useParams()
    const [cleanDataDetail, setcleanDataDetail] = useState([])

    const getDataDetail = async () => {
        const id = params.id
        const url = `${domain}/batery/${id}`
        const res = await axios.get(url)
        setcleanDataDetail(res.data.body)
    }

    useEffect(() => {
        getDataDetail()
    })

    return (
        <div className=''>
            <TitleComponent text={'Batería y sistema de carga'} />
            <div className="container shadow-left p-3" style={{backgroundColor: "White"}}>
                <TableCharger data={cleanDataDetail} />
            </div>
            
        </div>
    )
}

export default BateryCharger;