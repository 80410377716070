import config from '../config';
import axios from 'axios';

const domain = config.api_domain.domain


export const LoaderTypes = async (type) => {
  try {
    const region = localStorage.getItem('region')
    const response = await axios.get(`${domain}/all_electric_type/${region}/${type}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default LoaderTypes
