import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoaderCategories from "../../services/loaderCategories";
import { useMediaQuery } from "react-responsive";
import Spinner from "../../components/spinner/Spinner";
import UpperFormatt from "../../components/UpperFormatt";
import "../../styles/contens.css";
import "../../styles/global.css";
import altImage from '../../images/logo/auteco-logo.png'

const url_admin = `https://blue-test.lucia.com.co/admin/charts`;

const getImageUrlOrAlt = (imageUrl) => {
  if (typeof (imageUrl) === "string") {
    return imageUrl;
  }
  return altImage;
};

const Vehicles = ({admin, superAdmin}) => {
  const [data, setData] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(0);
  const [loading, setLoading] = useState(true);
  console.log(admin, superAdmin)
  const isSmallScreen = useMediaQuery({ query: "(max-width: 800px)" });
  const isMediumScreen = useMediaQuery({ query: "(max-width: 1100px)" });
  const isLargeScreen = useMediaQuery({ query: "(max-width: 1600px)" });
  const itemsPerGroup = isSmallScreen
    ? 1
    : isMediumScreen
      ? 2
      : isLargeScreen
        ? 3
        : 4;

  const region = localStorage.getItem(`region`)      
  console.log(region)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const categoriesData = await LoaderCategories();
        setData(categoriesData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePrevClick = () => {
    setCurrentGroup((prevGroup) => prevGroup - 1);
  };

  const handleNextClick = () => {
    setCurrentGroup((prevGroup) => prevGroup + 1);
  };

  const renderCategories = () => {
    if (loading) {
      return <Spinner />;
    }

    if (!data || data.body.length === 0) {
      return <h2>Sin categorías disponibles</h2>;
    }

    const startIndex = currentGroup * itemsPerGroup;
    const endIndex = startIndex + itemsPerGroup;
    const categoriesToShow = data.body.slice(startIndex, endIndex);

    return categoriesToShow.map((item) => (
      <div className="categories" key={item.id}>
        <div className="card m-5 shadow-blue-right" id="cards">
          <Link to={`/types/${item.category}`}>
            <p className="category text-center mt-3" id="textCategories">
              <UpperFormatt string={item.category} />
              <div className="img-ref" 
              style={{
                padding: '15px'
              }}>
                <div               
                style={{
                  backgroundImage: `url(${getImageUrlOrAlt(item.img_ref)})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain', 
                  backgroundPosition: 'center',
                  height: '265px',
                  width: '100%',
                  mixBlendMode: "multiply"
                }}>
                </div>
              </div>
            </p>
          </Link>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center subtitle"
        style={{ marginTop: "100px", marginBottom: "-120px", fontSize: "40px", fontWeight: "bold", textAlign: "center" }}
      >
        SELECCIÓN DE CATEGORÍA
      </div>

      <div
        id="carouselExampleDark"
        className="carousel carousel-dark slide d-flex justify-content-center align-items-center vh-100"
        data-bs-ride="carousel"
        data-bs-interval="false"
      >

        <div className="carousel-indicators"></div>
        <div className="carousel-inner d-flex justify-content-center align-items-center">
          {renderCategories()}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
          onClick={handlePrevClick}
          disabled={currentGroup === 0 || !data || data.body.length === 0}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
          onClick={handleNextClick}
          disabled={!data || data.body.length === 0 || (currentGroup + 1) * itemsPerGroup >= data.body.length}
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      {admin === true || superAdmin === true ? 
      <a href={url_admin}><button style={{margin: "0 20px"}}>Ir al Admin</button></a>:
      <> </>
    }
    </>
  );
};

export default Vehicles;