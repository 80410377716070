import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from '../../images/logo/auteco-logo.png'
import TitleComponent from '../../components/titleComponent/TitleComponent';

import './download.css';
// import '../../styles/global.css';

const DownloaderModal = ( ) => {
    const [clonedResult, setClonedResult] = useState('');
    const [yearsUse, setYearsUse] = useState()
    const [annualUse, setAnnualUse] = useState()

    useEffect(() => {
        //get params to set in the inputs
        const storedParams = JSON.parse(localStorage.getItem("dataParams"));
        if (storedParams) {
          setYearsUse(storedParams.yearsUse);
          setAnnualUse(storedParams.annualUse);
        }
      }, []);

    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
      };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = () => {
        const clonedResult = document.getElementById('environtment').innerHTML;
        setClonedResult(clonedResult);
        setShow(true);    
    };

    const getCurrentDateTime = () => {
        const currentDate = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return currentDate.toLocaleDateString('es-ES', options);
    };

    const handleDownload = () => {
        const capture = document.querySelector('.container-PDF');
        html2canvas(capture).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const width = 300
            const height = 340
            const pdf = new jsPDF('p', 'mm', 'a3');
            pdf.addImage(imgData, 'PNG', 0, 0, width, height);
            pdf.save('Aporte Ambiental.pdf');
        });
    };


    return (
        <>
            <button onClick={handleShow} className='
            shadow-left'>Generar PDF</button>

            <Modal size="lg" className='modal-results' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Descargable</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-pdf'>
                    <div className="container-PDF">
                        <div className="downloader-header">
                            <p className='p-modal date bold-title'>Fecha {getCurrentDateTime()}</p>
                            <div className="logo">
                                <img src={logo} width={120} />
                            </div>
                        </div>
                        <br />
                        <TitleComponent text={'APORTE AMBIENTAL'} />
                        <p className='p-modal subtitle' >Parámetros</p>
                        <p className='p-modal text'>Kilómetros al año</p>
                        <p className="p-modal bold-title">
                        {annualUse} 
                        </p>
                        <p className='p-modal text'>Años de uso</p>
                        <p className="p-modal bold-title">
                        {yearsUse} 
                        </p>
                        <br />
                        <div className="cloned-sec" dangerouslySetInnerHTML={{ __html: clonedResult }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={handleDownload}>
                        Descargar PDF
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DownloaderModal;
