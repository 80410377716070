import Swal from "sweetalert2";

const DataAlertExternalVehicle = async (data) => {
    const invalidFields = []
    const testInfo = {
        Precio: data[0].price || "",
        Peso_bruto_vehicular: data[0].gvwr || "",
        Capacidad_Tanque: data[0].fuel_cap || "",
        Cilindraje: data[0].cylinders || ""
      };

      Object.entries(testInfo).forEach(([key, value]) => {
        if (value === "" || value === null) {
            invalidFields.push(key);
        }
    });

    if (Object.keys(data).length === 0) {
        return false;
    } else {
        if (Object.values(testInfo).some(value => value === "" || value === null)) {
            Swal.fire({
                title: "Datos Faltantes",
                text: `Los siguientes campos no están completos o son nulos: ${invalidFields.join(", ")}`,
                icon: "error"
            });
        }
    }
}

export default DataAlertExternalVehicle