import React, { useState, useEffect } from 'react';
import UpdateAxes from './updates/UpdateAxes';
import UpdateBatery from './updates/UpdateBatery';
import UpdateBodywork from './updates/UpdateBodywork';
import UpdateBrakes from './updates/UpdateBrakes';
import UpdateCharger from './updates/UpdateCharger';
import UpdateDirection from './updates/UpdateDirection';
import UpdateSpeeds from './updates/UpdateSpeeds';
import UpdateFeature from './updates/UpdateFeature';
import UpdateEngine from './updates/UpdateEngine';
import UpdateHomologation from './updates/UpdateHomologation';
import UpdateVehicleData from './updates/UpdateVehicleData';
import UpdatePrice from './updates/UpdatePrice';
import UpdateMeasures from './updates/UpdateMeasures';
import UpdateImages from './updates/UpdateImages';
import { Link } from "react-router-dom";
import DataAlertExternalVehicle from '../../../scripts/DataAlertsExternalVehicle';
import config from '../../../config';
import GetDataDetail from '../../../services/loaderService';

import './style.css'
import '../../../styles/global.css'

import { useParams } from "react-router-dom";

const UpdateVehicle = () => {

  const params = useParams();
  const id = params.id;
  const category = params.category
  const fuel = params.fuel
  const [hideBodywork, setHideBodywork] = useState(false)
  const [hideTable, setHideTable] = useState(false)
  const [hideField, setHideField] = useState(false)

  const domain = config.api_domain.domain
  const url = `${domain}/features`;
  const [cleanDataDetail, setCleanDataDetail] = useState([]);

  const handleDatas = async () => {
    await GetDataDetail(setCleanDataDetail, url, id);
  }

  if (fuel !== "Eléctrico" && cleanDataDetail.length > 0) {
    DataAlertExternalVehicle(cleanDataDetail)
  }

  useEffect(() => {
    category.toLowerCase()
    if (category === 'camion' || category === 'minitruck') {
      setHideBodywork(true);
    }

    if (fuel !== 'Eléctrico') {
      setHideField(true)
      setHideTable(true)
      setHideBodywork(true)
    }

    handleDatas()
  }, [category, url, params.id, fuel]);

  return (
    <div>
      <h2 className='titleComponent'>Información del vehículo</h2>
      <div className='container'>
        <br />
        <br />
        <br />
        <div className="update-scheme">
          <div className="update-form shadow-left" >
            <h2>Mantenimientos</h2>
            <br /><br />
            <Link to={`/admin/maintenances/${id}`}><button>Editar Mantenimientos</button></Link>
          </div>
          <div className="update-form shadow-left">
            <h2>Información</h2>
            <UpdateVehicleData id={id} />
          </div>
          <div className="update-form shadow-left">
            <h2>Parámetros</h2>
            <UpdatePrice id={id} />
          </div>
          <div className="update-form shadow-left" hidden={hideTable}>
            <h2>Homologación</h2>
            <UpdateHomologation id={id} />
          </div>
          <div className="update-form shadow-left">
            <h2>Generalidades</h2>
            <UpdateFeature id={id} fields={hideField} />
          </div>
          <div className="update-form shadow-left" hidden={hideTable}>
            <h2>Ejes</h2>
            <UpdateAxes id={id} />
          </div>
          <div className="update-form shadow-left">
            <h2>Dirección</h2>
            <UpdateDirection id={id} fields={hideField} />
          </div>
          <div className="update-form shadow-left" hidden={hideTable}>
            <h2>Velocidades</h2>
            <UpdateSpeeds id={id} />
          </div>
          <div className="update-form shadow-left" hidden={hideTable}>
            <h2>Frenos</h2>
            <UpdateBrakes id={id} />
          </div>
          <div className="update-form shadow-left" hidden={hideBodywork}>
            <h2>Carrocería</h2>
            <UpdateBodywork id={id} />
          </div>
          <div className="update-form shadow-left">
            <h2>Dimensiones</h2>
            <UpdateMeasures id={id} fields={hideField} />
          </div>
          <div className="update-form shadow-left">
            <h2>Motor</h2>
            <UpdateEngine id={id} fields={hideField} />
          </div>
          <div className="update-form shadow-left" hidden={hideTable}>
            <h2>Batería</h2>
            <UpdateBatery id={id} />
          </div>
          <div className="update-form shadow-left" hidden={hideTable}>
            <h2>Cargador</h2>
            <UpdateCharger id={id} />
          </div>
          <div className="update-form shadow-left">
            <h2>Imágenes</h2>
            <UpdateImages id={id} hide={hideField} />
          </div>
        </div>
      </div>
      <br /><br /><br /><br />
    </div>

  );
};

export default UpdateVehicle;