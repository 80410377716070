import React from 'react'
import NumberFormatter from '../../../components/Formarts'
import NumberFormatSimple from '../../../components/Formaters/NumberFormatSimple'
import TitleComponent from '../../../components/titleComponent/TitleComponent'

const TableFeatures = ({ data }) => {
  if (!data) {
    return <p>No data available.</p>
  }
  return (
    <div>
      {data.map((datas, index) => (
        <div key={index}>
          <TitleComponent text={datas.name} />
          <div className="father-box container">
          <div className="child-box shadow-left">
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <th>Marca</th>
                  <td>{datas.feature_brand}</td>
                </tr>
                <tr>
                  <th>Línea</th>
                  <td>{datas.line}</td>
                </tr>
                <tr>
                  <th>Modelo</th>
                  <td>{datas.model}</td>
                </tr>
                <tr>
                  <th>Servicio</th>
                  <td>{datas.service}</td>
                </tr>
                <tr>
                  <th>Operación</th>
                  <td>{datas.operation}</td>
                </tr>
                <tr>
                  <th>Número de Ejes</th>
                  <td>{datas.axies}</td>
                </tr>
                <tr>
                  <th>Número de Llantas</th>
                  <td>{datas.number_weels}</td>
                </tr>
                <tr>
                  <th>Medida de Llantas</th>
                  <td>{datas.weels_measure}</td>
                </tr>
                <tr>
                  <th>Factor de Llanta</th>
                  <td><NumberFormatter number={datas.weels_factor} /></td>
                </tr>
                <tr>
                  <th>Peso <span className='units-lowercase'>[kg]</span></th>
                  <td><NumberFormatSimple number={datas.weight} /></td>
                </tr>
                <tr>
                  <th>Peso Eje Delantero <span className='units-lowercase'>[kg]</span></th>
                  <td><NumberFormatSimple number={datas.front_axle_weight} /></td>
                </tr>
                <tr>
                  <th>Peso Eje Trasero <span className='units-lowercase'>[kg]</span></th>
                  <td><NumberFormatSimple number={datas.back_axle_weight} /></td>
                </tr>
                <tr>
                  <th>Peso Bruto Vehicular <span className='units-lowercase'>[kg]</span></th>
                  <td><NumberFormatSimple number={datas.gvwr} /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="child-box shadow-left">
            <table className="table table-striped-columns p-2">
              <tbody>
              <tr>
                  <th>Peso Bruto Combinado <span className='units-lowercase'>[kg]</span></th>
                  <td><NumberFormatSimple number={datas.gross_weigh_combined} /></td>
                </tr>
                <tr>
                  <th>Carga disponible máxima <span className='units-lowercase'>[kg]</span></th>
                  <td><NumberFormatSimple number={datas.max_load} /></td>
                </tr>
                <tr>
                  <th>Capacidad de arranque en pendiente <span className='units-lowercase'>[%]</span></th>
                  <td><NumberFormatter number={datas.hill_start_ability}/></td>
                </tr>
                <tr>
                  <th>Velocidad máxima <span className='units-lowercase'>[kph]</span></th>
                  <td>{datas.max_speed}</td>
                </tr>
                <tr>
                  <th>Longitud total del vehículo <span className='units-lowercase'>[mm]</span></th>
                  <td><NumberFormatSimple number={datas.total_length_measure} /></td>
                </tr>
                <tr>
                  <th >Longitud de la cabina <span className='units-lowercase'>[mm]</span></th>
                  <td><NumberFormatSimple number={datas.total_length_cab}/></td>
                </tr>
                <tr>
                  <th >Alto del vehículo <span className='units-lowercase'>[mm]</span></th>
                  <td><NumberFormatSimple number={datas.vehicle_height}/></td>
                </tr>
                <tr>
                  <th>Ancho del vehículo <span className='units-lowercase'>[mm]</span></th>
                  <td><NumberFormatSimple number={datas.vehicle_width}/></td>
                </tr>
                <tr>
                  <th >Distancia entre ejes <span className='units-lowercase'>[mm]</span></th>
                  <td><NumberFormatSimple number={datas.axies_distance}/></td>
                </tr>
                <tr>
                  <th >Voladizo anterior <span className='units-lowercase'>[mm]</span></th>
                  <td><NumberFormatSimple number={datas.inner_cantilever}/></td>
                </tr>
                <tr>
                  <th >Voladizo posterior <span className='units-lowercase'>[mm]</span></th>
                  <td><NumberFormatSimple number={datas.rear_overhang}/></td>
                </tr>
                <tr>
                  <th>Distancia entre ejes TANDEM <span className='units-lowercase'>[mm]</span></th>
                  <td><NumberFormatSimple number={datas.axies_distance_tandem}/></td>
                </tr>
                <tr>
                  <th>Distancia King-pin al centro eje trasero <span className='units-lowercase'>[mm]</span></th>
                  <td>{datas.back_axle_distance_kingpin}</td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TableFeatures