import React, { useState } from 'react'
import DownloadPdf from '../../../components/DownloadPdf'
import NumberFormatter from '../../../components/Formarts';
import BarChart from '../../../components/charts/BarChart';
import LineChart from '../../../components/charts/LineChart';
import TitleComponent from '../../../components/titleComponent/TitleComponent';
import TooltipModal from '../../../components/modals/ToolTipModal';

const TableMaintenance = ({ data, type }) => {
  const [showAllElectricRutines, setShowAllAllElectricRutines] = useState(false);
  const [showAllFuelRutines, setShowAllFuelRutines] = useState(false);
  const [showElectricPreventive, setShowElectricPreventive] = useState(false);
  const [showFuelPreventive, setShowFuelPreventive] = useState(false);
  const [showTables, setShowTables] = useState(false);

  const options = {
    useGrouping: true,
    minimumFractionDigits: 0,
  };

  if (!data || data.length <= 0 || data.error || data === null || data === []) {
    return <p className="subtitle">Sin parámetros asignados</p>;
  }

  const extractChartData = (data) => {
    if (Array.isArray(data)) {
      const values = data.map(item => item.total);
      return values;
    } else {
      return [];
    }
  };

  const toggleTables = () => {
    setShowTables(!showTables);
  };

  let chartData;
  let secondChartData;

  if (data.length > 0) {
    chartData = extractChartData(data[0].maintenanceFuelProyected?.presents ?? 0);
    secondChartData = extractChartData(data[0].maintenanceProyected?.presents ?? 0);
  }

  return (
    <div>{data.length > 0 && (
      <>
        {/* Proyección de mantenimientos */}
        <div className="father-box">
          <div className="child-box shadow-left">
            <div className="title">
              <h5 >MANTENIMIENTOS PREVENTIVOS EN VEHÍCULO ELÉCTRICO <TooltipModal text={'Los mantenimientos preventivos son un conjunto de revisiones periódicas que ofrece la compañía automotriz para una inspección detallada del vehículo cada cierto kilometraje recorrido'} title={'Proyección de mantenimientos'} /></h5>
              <br />
            </div>
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head col-2">Año</td>
                  <td className="table-head">Costo anual</td>
                  <td className="table-head">Valor presente <TooltipModal text={'Corresponde al Valor Presente Neto de cada uno de los años en ejecución, el cual permite establecer un costo total a día de hoy en una proyección financiera'} title={'Valor presente'}/></td>
                </tr>
                {Array.isArray(data) && data[0]?.maintenanceProyected?.presents ? (
                  data[0].maintenanceProyected.presents.map((datas, index) => (
                    <tr key={index}>
                      <td>{index + 1} </td>
                      <td>$ <NumberFormatter number={datas['total']} /></td>
                      <td>$ <NumberFormatter number={datas['data_proyected']} /></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>Sin dato disponible</td>
                  </tr>
                )}
              </tbody>
            </table>
            <h4 className="big-result-text">Total: $ <NumberFormatter number={data[0].maintenanceProyected?.totals ?? 0} /> </h4>
          </div>
          <div className="child-box shadow-left">
            <div className="title">
              <h5 >MANTENIMIENTOS PREVENTIVOS EN VEHÍCULO DE COMBUSTIÓN <TooltipModal text={'Los mantenimientos preventivos son un conjunto de revisiones periódicas que ofrece la compañía automotriz para una inspección detallada del vehículo cada cierto kilometraje recorrido'} title={'Proyección de mantenimientos'} /></h5>
              <br />
            </div>
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head col-2">Año</td>
                  <td className="table-head">Costo anual</td>
                  <td className="table-head">Valor presente <TooltipModal text={'Corresponde al Valor Presente Neto de cada uno de los años en ejecución, el cual permite establecer un costo total a día de hoy en una proyección financiera'} title={'Valor presente'}/></td>
                </tr>
                {Array.isArray(data) && data[0]?.maintenanceFuelProyected?.presents ? (
                  data[0].maintenanceFuelProyected.presents.map((datas, index) => (
                    <tr key={index}>
                      <td>{index + 1} </td>
                      <td>$ <NumberFormatter number={datas['total']} /></td>
                      <td>$ <NumberFormatter number={datas['data_proyected']} /></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>Sin dato disponible</td>
                  </tr>
                )}
              </tbody>
            </table>
            <h4 className="big-result-text">Total: $ <NumberFormatter number={data[0].maintenanceFuelProyected?.totals ?? 0} /> </h4>
          </div>
        </div>
        <br />
        <button onClick={toggleTables} className='shadow-left'>
          {showTables ? 'Ocultar tablas' : 'Mostrar tablas'}
        </button>

        {showTables && (

          <div className="father-box">
            <div className="child-box shadow-left">
              <div className="father-box">
                <div className="child-sm-box">
                  <div className="title">
                    <h5 >RUTINAS DE MANTENIMIENTO DEL VEHÍCULO ELÉCTRICO <TooltipModal text={'En las rutinas de mantenimiento se establece un costo, definido previamente, para la revisión del vehículo cada cierto kilometraje. Cada revisión comprende un número determinado de inspecciones técnicas establecidas por la compañía'} title={'Rutinas de mantenimiento'} /></h5>
                    <br />
                  </div>
                  <table className="table table-striped-columns p-2">
                    <tbody>
                      <tr>
                        <td className="table-head">Kilometraje</td>
                        <td className="table-head">Costo de revisión</td>
                      </tr>
                      {data[0].electricMaintenance && data[0].electricMaintenance.length > 0 ? (
                        data[0].electricMaintenance.slice(0, showAllElectricRutines ? undefined : 5).map((datas, index) => (
                          <tr key={index}>
                            <td>{datas['mileage'].toLocaleString(undefined, options).replace(/,/g, '.')} km</td>
                            <td>$ <NumberFormatter number={datas[`price`]} /></td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2}>Sin datos de electricMaintenance</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <button onClick={() => setShowAllAllElectricRutines(!showAllElectricRutines)}>
                    {showAllElectricRutines ? 'Ver menos' : 'Ver más'}
                  </button>
                </div>
                <div className="child-sm-box">
                  <div className="title">
                    <h5 >DETALLE DE MANTENIMIENTOS PREVENTIVOS DEL VEHÍCULO ELÉCTRICO <TooltipModal text={'Cantidad de revisiones ejecutadas por cada año de proyección, con base en el uso anual establecido en la ventana de parámetros y la lista de mantenimientos preventivos de cada vehículo'} title={'Mantenimientos preventivos'} /></h5>
                    <br />
                  </div>
                  <table className="table table-striped-columns p-2">
                    <tbody>
                      <tr>
                        <td className="table-head col-2">Año</td>
                        <td className="table-head">Revisiones por año</td>
                        <td className="table-head">Costo de revisión</td>
                      </tr>
                      {data[0].maintenanceRutines.slice(0, showElectricPreventive ? undefined : 1).map((datas, index) => (
                        <tr key={index}>
                          <td>{index + 1} </td>
                          <td>{datas[`rutines`].map((value, i) => (
                            <p key={i} >{value.toLocaleString(undefined, options).replace(/,/g, '.')} km
                            </p>
                          ))}</td>
                          <td>$ <NumberFormatter number={datas['total']} /></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <button onClick={() => setShowElectricPreventive(!showElectricPreventive)}>
                    {showElectricPreventive ? 'Ver menos' : 'Ver más'}
                  </button>
                </div>
              </div>
            </div>
            <div className="child-box shadow-left">
              <div className="father-box">
                <div className="child-sm-box">
                  <div className="title">
                    <h5 >RUTINAS DE MANTENIMIENTO DEL VEHÍCULO A COMBUSTIÓN <TooltipModal text={'En las rutinas de mantenimiento se establece un costo, definido previamente, para la revisión del vehículo cada cierto kilometraje. Cada revisión comprende un número determinado de inspecciones técnicas establecidas por la compañía'} title={'Rutinas de mantenimiento'} /></h5>
                    <br />
                  </div>
                  <table className="table table-striped-columns p-2">
                    <tbody>
                      <tr>
                        <td className="table-head">Kilometraje</td>
                        <td className="table-head">Costo de revisión</td>
                      </tr>
                      {data[0].fuelMaintenance && data[0].fuelMaintenance.length > 0 ? (
                        data[0].fuelMaintenance.slice(0, showAllFuelRutines ? undefined : 5).map((datas, index) => (
                          <tr key={index}>
                            <td>{datas['mileage'].toLocaleString(undefined, options).replace(/,/g, '.')} km</td>
                            <td>$ <NumberFormatter number={datas[`price`]} /></td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2}>Sin datos de fuelMaintenance</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <br />
                  <button onClick={() => setShowAllFuelRutines(!showAllFuelRutines)}>
                    {showAllFuelRutines ? 'Ver menos' : 'Ver más'}
                  </button>
                </div>
                <div className="child-sm-box">
                  <div className="title">
                    <h5 >DETALLE DE MANTENIMIENTOS PREVENTIVOS DEL VEHÍCULO A COMBUSTIÓN <TooltipModal text={'Cantidad de revisiones ejecutadas por cada año de proyección, con base en el uso anual establecido en la ventana de parámetros y la lista de mantenimientos preventivos de cada vehículo'} title={'Mantenimientos preventivos'} /></h5>
                    <br />
                  </div>
                  <table className="table table-striped-columns p-2">
                    <tbody>
                      <tr>
                        <td className="table-head col-2">Año</td>
                        <td className="table-head">Revisiones por año</td>
                        <td className="table-head">Costo de revisión</td>
                      </tr>
                      {data[0].maintenanceFuelRutines.slice(0, showFuelPreventive ? undefined : 1).map((datas, index) => (
                        <tr key={index}>
                          <td>{index + 1} </td>
                          <td>{datas[`rutines`].map((value, i) => (
                            <p key={i} >{value.toLocaleString(undefined, options).replace(/,/g, '.')} km
                            </p>
                          ))}</td>
                          <td>$ <NumberFormatter number={datas['total']} /></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <button onClick={() => setShowFuelPreventive(!showFuelPreventive)}>
                    {showFuelPreventive ? 'Ver menos' : 'Ver más'}
                  </button>
                </div>
              </div>

            </div>
          </div>
        )}

        <br />
        <hr />
        <br />

        <TitleComponent text={'Consumibles'} />

        {/* Costos consumibles */}
        <div className="father-box">
          <div className="child-box shadow-left">
            <div className="title">
              <h5 >COSTO DE CONSUMIBLES DEL VEHÍCULO ELÉCTRICO <TooltipModal text={'Los consumibles hacen referencia a la lista de modificaciones y/o reparaciones que el usuario realiza en el vehículo, y que no están incluidas dentro de los mantenimientos preventivos ofrecidos por la compañía. Ej: cambio de batería auxiliar, cambio de llantas, partes correctivas, etc.'} title={'Consumibles'} /></h5>
              <br />
            </div>
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head col-2">Año</td>
                  <td className="table-head">Costo de consumibles</td>
                </tr>
                {data[0].consumablesProyected.electrical_present_value.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <h4 className="big-result-text">Total: $ <NumberFormatter number={data[0].consumablesProyected?.total_electrical_proyected ?? 0} /> </h4>
          </div>
          <div className="child-box shadow-left">
            <div className="title">
              <h5 >COSTO DE CONSUMIBLES DEL VEHÍCULO A COMBUSTIÓN <TooltipModal text={'Los consumibles hacen referencia a la lista de modificaciones y/o reparaciones que el usuario realiza en el vehículo, y que no están incluidas dentro de los mantenimientos preventivos ofrecidos por la compañía. Ej: cambio de batería auxiliar, cambio de llantas, partes correctivas, etc.'} title={'Consumibles'} /></h5>
              <br />
            </div>
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head col-2">Año</td>
                  <td className="table-head">Costo de consumibles </td>
                </tr>
                {data[0].consumablesProyected.fuel_present_value.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td> $ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <h4 className="big-result-text">Total: $ <NumberFormatter number={data[0].consumablesProyected?.total_fuel_proyected ?? 0} /> </h4>
          </div>
        </div>

        <br />
        <hr />
        <br />

        {/* Graficas */}
        <div className="father-box">
          <div className="child-box shadow-left">
            <LineChart name={'Costo de mantenimientos preventivos'} data={[
              {
                name: 'Combustión',
                data: chartData,
              },
              {
                name: 'Eléctrico',
                data: secondChartData,
              }
            ]} />
          </div>

          <div className="child-box shadow-left">
            <BarChart name={'Total en consumibles'} data={{
              categories: ['Consumibles', 'Mantenimientos'],
              series: [
                {
                  name: 'Eléctrico',
                  data: [parseFloat(data[0].consumablesProyected?.total_electrical_proyected ?? 0), parseFloat(data[0].maintenanceProyected?.totals ?? 0)],
                },
                {
                  name: 'Combustión',
                  data: [parseFloat(data[0].consumablesProyected?.total_fuel_proyected ?? 0), parseFloat(data[0].maintenanceFuelProyected?.totals ?? 0)],
                }
              ],
            }} />
          </div>
        </div>
      </>
    )}
      <br />
      {/* <DownloadPdf dataPdf={data} /> */}
      <br /><br />
    </div>
  )
}

export default TableMaintenance