import React from "react";
import { useEffect, useState } from "react";
import LoaderUtilsService from "../../../services/admin/utils/loaderUtilsService";
import Spinner from "../../../components/spinner/Spinner";
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import UpperFormatt from "../../../components/UpperFormatt";
import UpdateService from "../../../services/admin/updates/updateUtils";

const AllVehiclesAdmin = () => {
    const [loading, setLoading] = useState(true);
    const [consumables, setConsumables] = useState([]);
    const [editStates, setEditStates] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const customer = localStorage.getItem('region')
                const data = await LoaderUtilsService(`consumables/${customer}`);
                setConsumables(data.filter(item => item.consumable_id > 0).sort((a, b) => a.id - b.id));
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const handleEdit = (id) => {
        setEditStates(prevState => ({ ...prevState, [id]: true }));
    };

    const handleUpdate = async (id) => {
        const itemToUpdate = consumables.find(item => item.consumable_id === id);

        try {
            let dataValue = { value: itemToUpdate.cost };
            await UpdateService("update_consumables", id, dataValue);
            setEditStates(prevState => ({ ...prevState, [id]: false }));
        } catch (error) {
            console.error(error);
        }
    };

    const handleValue = (id, e) => {
        const value = parseFloat(e.target.value);
        setConsumables(prevConsumables => prevConsumables.map(item => item.consumable_id === id ? { ...item, cost: value } : item));
    };

    const handleCancel = (id) => {
        setEditStates(prevState => ({ ...prevState, [id]: false }));
    };

    const groupedData = consumables.reduce((acc, item) => {
        if (!acc[item.currency]) {
            acc[item.currency] = [];
        }
        acc[item.currency].push(item);
        return acc;
    }, {});

    if (loading) {
        return <Spinner />;
    }

    return (
        <div>
            <TitleComponent text={'Lista de consumibles del vehículo'} />
            <br />
            <div className="container shadow-left p-3" style={{ background: "white" }}>
                <div className="">
                    <h3 className="subtitle">Lista de consumibles</h3>
                    <br />
                    {Object.keys(groupedData).map(currency => (
                        <div key={currency}>
                            <h3>{currency}</h3>

                            <button class="btn btn-primary m-1" type="button" data-bs-toggle="collapse" data-bs-target={`#multiCollapse${currency}`} aria-expanded="true" aria-controls="multiCollapseExample2">Ver tabla</button>

                            <table className="table collapse multi-collapse" id={`multiCollapse${currency}`}>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Costo</th>
                                        <th className="col-2">Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupedData[currency].map((data) => (
                                        <tr key={data.consumable_id}>
                                            <td><UpperFormatt string={data.item} /> ({data.currency}) </td>
                                            <td className="col-2">
                                                <input
                                                    type="number"
                                                    className='form-control'
                                                    onChange={(e) => handleValue(data.consumable_id, e)}
                                                    value={editStates[data.consumable_id] ? data.cost : data.cost.toLocaleString('es', { useGrouping: false, minimumFractionDigits: 0 }).replace(/,/g, '.')}
                                                    disabled={!editStates[data.consumable_id]}
                                                />
                                            </td>
                                            <td className="col-options col-1">
                                                {editStates[data.consumable_id] ? (
                                                    <>
                                                        <button
                                                            className="btn btn-primary m-1"
                                                            onClick={() => handleUpdate(data.consumable_id)}
                                                        >
                                                            Actualizar
                                                        </button>
                                                        <button
                                                            className="btn btn-secondary m-1"
                                                            onClick={() => handleCancel(data.consumable_id)}
                                                        >
                                                            Cancelar
                                                        </button>
                                                    </>
                                                ) : (
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => handleEdit(data.consumable_id)}
                                                    >
                                                        Editar
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <br /><br />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AllVehiclesAdmin;