import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TableElectricEngine from "./components/TableElectricEngine";
import GetDataDetail from "../../services/loaderService";
import TitleComponent from "../../components/titleComponent/TitleComponent";
import config from '../../config';

const domain = config.api_domain.domain
const ElectricEngine = () => {
  const params = useParams();
  const id = params.id;
  const url = `${domain}/engine`;

  const [cleanDataDetail, setcleanDataDetail] = useState([]);

  useEffect(() => {
    GetDataDetail(setcleanDataDetail, url, id);
  }, [id, url, setcleanDataDetail]);

  return (
    <div className="">
      <TitleComponent text={"Motor Eléctrico"} />
      <div className="container shadow-left p-3" style={{backgroundColor: "White"}}>
          <TableElectricEngine data={cleanDataDetail} />
      </div>
    </div>
  );
};

export default ElectricEngine;
