import React, { useState, useEffect } from "react";
import LoaderUtilsService from "../../../services/admin/utils/loaderUtilsService";
import UpdateUtilsService from "../../../services/admin/updates/updateUtils";
import AddUtilsService from "../../../services/admin/adds/AddUtilService";
import Spinner from "../../../components/spinner/Spinner";
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import UpperFormat from "../../../components/UpperFormatt"; // Corregido el nombre de componente

const ListCategories = ({permission}) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [category, setCategory] = useState("");
  const [updatedIndexes, setUpdatedIndexes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await LoaderUtilsService("categories");
        setCategories(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);


  const handleUpdate = async (index) => {
    const updatedCategories = [...categories];
    const { id, state, img_ref } = updatedCategories[index];

    try {
      let dataValue = { "id": id, "state": state, "img_ref": img_ref };
      await UpdateUtilsService("update_category", id, dataValue);
      setCategories(updatedCategories);

      // Actualizar el estado de los índices actualizados
      setUpdatedIndexes((prevIndexes) => [...prevIndexes, index]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleState = (index, e) => {
    const updatedCategories = [...categories];
    updatedCategories[index].state = e.target.checked ? "active" : "inactive";
    setCategories(updatedCategories);
  };

  const handleImageChange = (index, e) => {
    const updatedCategories = [...categories];
    updatedCategories[index].img_ref = e.target.value;
    setCategories(updatedCategories);
  };

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const data = { category: category };
    await AddUtilsService("insert_category", data);
    setCategory("");
    setShowForm(false);

    try {
      const updatedCategories = await LoaderUtilsService("categories");
      setCategories(updatedCategories);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
      permission ? (
        <>    <div className="">
        <TitleComponent text={"Administrador de categorías"} />
        <br />
        <div className="container shadow-left p-2" style={{ background: "white" }}>
          <div>
            <h3 className="subtitle">Categorías</h3>
            <br />
            <table className="table">
              <thead>
                <tr>
                  <th>Categoría</th>
                  <th>Estado</th>
                  <th>Activar/Desactivar</th>
                  <th>Imagen de referencia</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((data, index) => ( // Agregado el atributo key aquí
                  <tr key={index}>
                    <td className="category-uppercase">
                      <UpperFormat string={data.category} />
                    </td>
                    <td>
                      <UpperFormat string={data.state} />
                    </td>
                    <td className="col-options">
                      <div className="form-check form-switch"> {/* Corregido class a className */}
                        <input
                          className="form-check-input"
                          name="state"
                          type="checkbox"
                          id={`flexSwitchCheckDefault-${index}`}
                          checked={data.state === "active" || ""}
                          onChange={(e) => {
                            handleState(index, e);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className='form-control'
                        name="img_ref"
                        value={data.img_ref}
                        onChange={(e) => {
                          handleImageChange(index, e);
                        }}
                      />
                    </td>
                    <button style={{backgroundColor: '#0064C8', color: "white"}} className="btn btn-primary" onClick={() => { handleUpdate(index); }}>
                      {updatedIndexes.includes(index) ? "Actualizado" : "Actualizar"}
                    </button>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <br />
        <br />
        <div className="col-md-6">
          {showForm ? (
            <form onSubmit={handleFormSubmit}>
              <input
                type="text"
                className='form-control'
                placeholder="Nombre de la categoría"
                onChange={handleCategory}
                required
              />
              <button type="submit">Crear</button>
            </form>
          ) : (
            <div className="btn-new">
              <button onClick={handleShowForm} className="shadow-left">Nueva Categoría</button>
            </div>
          )}
        </div>
      </div></>
       ):(
        <>Sin permisos de Super admin</>
       )
  );
};

export default ListCategories;
