import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../components/spinner/Spinner";
import LoaderUtilsService2 from "../../../services/admin/utils/loaderUtilsService2";
import TitleComponent from "../../../components/titleComponent/TitleComponent";
// import TooltipModal from "../../../components/modals/ToolTipModal";
import AddUtilsService from "../../../services/admin/adds/AddUtilService";
import { FaEdit, FaPlus } from "react-icons/fa";
import '../../../styles/global.css'

const ListCountries = ({ permission }) => {
    const [loading, setLoading] = useState(true);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    // const [code, setCode] = useState("");
    // const [flag, setFlag] = useState("");
    // const [currency, setCurrency] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({});

    const setDatas = async () => {
        try {
            const countries_data = await LoaderUtilsService2("countries");
            setCountries(countries_data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const data = {
            country_name: formData.country_name || '',
            code: formData.code || '',
            flag_image: formData.flag_image || '',
            currency: formData.currency || ''
        };

        await AddUtilsService("insert_country", data);
        setCountry("");
        setShowForm(false);
        setDatas();
    };

    const handleCloseForm = () =>{
        setShowForm(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    useEffect(() => {
        setDatas();
    }, []);

    if (loading) {
        return <Spinner />;
    }

    return (
        permission ? (
            <>
                <div>
                    <TitleComponent text={"Usuarios"} />
                    <div className="container">
                        <div className="row">
                            {
                                (!countries || countries.length <= 0 || countries.error || countries === null) ? (
                                    <div>Sin paises disponibles</div>
                                ) : (
                                    countries.filter(data => data.code !== 'admin').map((data, index) => (
                                        <Link to={`/admin/update_custom/${data.country_name}`} key={index} className="col-sm-3 p-3" style={{textDecoration: 'none'}}>
                                            <div class="card card-country shadow-left" style={{ width: "18rem", height: "14rem" }}>
                                                <img src={data.flag_image} className="card-img-top" alt="Bandera del país" style={{ maxHeight: "160px" }} />
                                                <div className="card-body">
                                                    <h3 class="card-title">{data.country_name} <span>({data.code})</span> </h3>
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                )
                            }
                            <div onClick={() => setShowForm(!showForm)} className="col-sm-1 plus-container shadow-left" ><FaPlus color="white" size={20} className="plus-centered" /></div>
                        </div>
                        <div className="container">
                            {showForm && (
                                <form className="" onSubmit={handleFormSubmit}>
                                    <hr />
                                    <h3>Añadir nuevo país</h3>
                                    <div className="row">
                                        <div className="col-4 m-3">
                                            <label>Nombre del país</label>
                                            <input
                                                name="country_name"
                                                type="text"
                                                className='form-control'
                                                value={formData.country_name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-4 m-3">
                                            <label>Código</label>
                                            <input
                                                name="code"
                                                type="text"
                                                className='form-control'
                                                value={formData.code}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-4 m-3">
                                            <label>Imagen</label>
                                            <input
                                                name="flag_image"
                                                type="text"
                                                className='form-control'
                                                value={formData.flag_image}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-4 m-3">
                                            <label>Moneda</label>
                                            <input
                                                name="currency"
                                                type="text"
                                                className='form-control'
                                                value={formData.currency}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-success shadow-left" style={{ width: "150px" }}>Guardar</button>
                                    <button onClick={handleCloseForm} className="btn btn-secondary shadow-left" style={{ width: "150px" }}>Cerrar</button>
                                </form>
                            )}
                            <hr />
                        </div>
                        <div className="row">
                            <h3>Personalización de Súper Admin</h3>
                            {
                                (!countries || countries.length <= 0 || countries.error || countries === null) ? (
                                    <div>Sin configuración de Administrador disponible</div>
                                ) : (
                                    countries.filter(data => data.code === 'admin').map((data, index) => (
                                        <Link to={`/admin/update_super_admin/${data.code}`} key={index} className="col-sm-3 p-3" style={{textDecoration: 'none'}}>
                                            <div class="card card-country shadow-left" style={{ width: "18rem", height: "14rem" }}>
                                                <img src={data.flag_image} className="card-img-top" alt="Bandera del país" style={{ maxHeight: "160px" }} />
                                                <div className="card-body">
                                                    <h3 class="card-title">{data.country_name}</h3>
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                )
                            }
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <>Sin permisos de Super admin</>
        )
    );
};

export default ListCountries;
