import axios from 'axios';
import config from '../config';

const domain = config.api_domain.domain

const LoaderAllVehicles = async (detail) => {
    try {
        const region = localStorage.getItem("region")
        const url = (`${domain}/fuel_vehicles/${region}`)
        const res = await axios.get(url)
        detail(res.data.body)
        
    } catch (error) {
        console.error(error);
        detail(null);
    }
    return detail
}

export default LoaderAllVehicles