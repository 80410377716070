import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TableFeatures from "./components/TableFeatures";
import GetDataDetail from "../../services/loaderService";
import DataAlert from "../../scripts/DataAlerts.js";
import config from '../../config';

const domain = config.api_domain.domain;

const Features = () => {
  const params = useParams();
  const id = params.id;
  const url = `${domain}/features`;
  const [cleanDataDetail, setCleanDataDetail] = useState([]);

  // Fetch data when component mounts or id/url changes
  useEffect(() => {
    GetDataDetail(setCleanDataDetail, url, id);
  }, [id, url]);

  // Handle data alert and store country_name in localStorage
  useEffect(() => {
    const handleDataAlertAndLocalStorage = async () => {
      if (cleanDataDetail.length > 0) {
        const countryName = cleanDataDetail[0].country_name;
        const currency = cleanDataDetail[0].currency;
        
        // Store country_name in localStorage if it exists
        if (countryName) {
          localStorage.setItem('country_name', countryName);
          localStorage.setItem('currency', currency);
        }

        // Trigger DataAlert with the clean data detail
        await DataAlert(cleanDataDetail[0]);
      }
    };

    handleDataAlertAndLocalStorage();
  }, [cleanDataDetail]);

  return (
    <div>
      <TableFeatures data={cleanDataDetail} />
    </div>
  );
};

export default Features;
