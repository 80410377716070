import { useParams, useNavigate, Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';

import SidebarAux from "../../components/SidebarAux";
import TitleComponent from "../../components/titleComponent/TitleComponent";
import Homologation from "../homologation/Homologation";

const Mecanic = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!params.id) {
      setTimeout(() => {
        navigate(`/home/${params.id}/camion/subsystems/mecanic/${params.id}/homologation/${params.id}`);
      }, 0);
    }
  }, [params.id, navigate]);

  return (
    <div>
      <TitleComponent text={"Características mecánicas"} />
      <div className="container">
        <div className="father-box">
          <div className="child-box shadow-left">
            <SidebarAux id={params.id} type={params.type} />
          </div>
          <div className="child-box shadow-left">
            <Outlet className="content" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mecanic;

