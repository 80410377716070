import axios from 'axios';
import config from '../config';

const domain = config.api_domain.domain

const LoaderfuelByType = async (type) => {
    try {
        const region = localStorage.getItem("region")
        const url = (`${domain}/fuel_vehicles_by_type/${region}/${type}`)
        const res = await axios.get(url)
        return (res.data.body)
        
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default LoaderfuelByType