import React from 'react'
import NumberFormatter from '../../../components/Formarts'
import NumberFormatSimple from '../../../components/Formaters/NumberFormatSimple'

const TableCharger = ( {data} ) => {
  if (data===null) {
    return <p>No data available.</p>
}
  return (
    <div>
      <table className="table table-striped-columns p-2">
                {data.map((datas, index) => (
                    <tbody key={index}>
                        <th className='subtitle'>Batería</th>
                        <tr>
                            <th scope="row">Química</th>
                            <td>{datas.material}</td>
                        </tr>
                        <tr>
                            <th scope="row">Configuración</th>
                            <td>{datas.batery_config}</td>
                        </tr>
                        <tr>
                            <th scope="row">Número de ciclos</th>
                            <td>< NumberFormatSimple number={datas.cycles}/></td>
                        </tr>
                        <tr>
                            <th scope="row">Autonomía <span className='units-lowercase'>[km]</span></th>
                            <td>< NumberFormatSimple number={datas.autonomy}/></td>
                        </tr>
                        <tr>
                            <th scope="row">Capacidad nominal <span className='units-lowercase'>[Ah]</span></th>
                            <td>< NumberFormatSimple number={datas.nominal_cap}/></td>
                        </tr>
                        <tr>
                            <th scope="row">Voltaje nominal <span className='units-lowercase'>[V]</span></th>
                            <td>< NumberFormatSimple number={datas.nominal_volt}/></td>
                        </tr>
                        <tr>
                            <th scope="row">Energía nominal <span className='units-lowercase'>[kWh]</span></th>
                            <td>< NumberFormatter number={datas.nominal_energy}/></td>
                        </tr>
                        <th className='subtitle'>Sistema de carga 1</th>
                        <tr>
                            <th scope="row">Tipo de carga</th>
                            <td>{datas.charge_type_1}</td>
                        </tr>
                        <tr>
                            <th scope="row">Configuración</th>
                            <td>{datas.system_config_1}</td>
                        </tr>
                        <tr>
                            <th scope="row">Tiempo de carga <span className='units-lowercase'>[h]</span></th>
                            <td>{datas.charge_time_1}</td>
                        </tr>
                        <th className='subtitle'>Sistema de carga 2</th>
                        <tr>
                            <th scope="row">Tipo de carga</th>
                            <td>{datas.charge_type_2}</td>
                        </tr>
                        <tr>
                            <th scope="row">Configuración</th>
                            <td>{datas.system_config_2}</td>
                        </tr>
                        <tr>
                            <th scope="row">Tiempo de carga <span className='units-lowercase'>[h]</span></th>
                            <td>< NumberFormatter number={datas.charge_time_2}/></td>
                        </tr>
                    </tbody>
                ))}
            </table>
    </div>
  )
}

export default TableCharger