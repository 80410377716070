import React, { useState, useEffect } from "react";
import Spinner from "../../../components/spinner/Spinner";
import LineChart from "../../../components/charts/LineChart";
import ColumnsChart from "../../../components/charts/ColumnsChart";
import LoaderUtilsService from "../../../services/admin/utils/loaderUtilsService"
import LoaderUtilsService2 from "../../../services/admin/utils/loaderUtilsService2"
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import NumberFormatter from "../../../components/Formarts";
import LoaderScraping from "../../../services/scrapingLoader";
import LineChartCounter from "../../../components/charts/LineChartCounter";

const DetailChart = () => {
    const [loading, setLoading] = useState(true);
    const [tes, setTes] = useState([])
    const [counter, setCounter] = useState([])
    const [counter2, setCounter2] = useState([])
    const [bateryPrice, setBateryPrice] = useState([]);
    const [scraping, setScraping] = useState([])
    const [lastTes, setLastTes] = useState()
    const customer = localStorage.getItem('region')

    const setDatas = async () => {
        try {
            //Set RateTes
            const tesRes = await LoaderUtilsService('tes')
            const batery_data = await LoaderUtilsService('batery_prices')
            const scraping_data = await LoaderUtilsService2(`scraping_data/0/0/${customer}`)
            // const scraping_data = await LoaderScraping(0, 0)
            const batery_price = batery_data[(batery_data.length - 1)].price
            const tesData = tesRes[0].data
            setLastTes(tesRes[0].date_time)
            const rates = Object.values(tesData).map(item => item.rate);
            const years = Object.values(tesData).map(item => item.year);
            setTes([rates, years])


            //Set Vehicle Counter
            const counterRes = await LoaderUtilsService(`all_counter/admin`)
            setCounter2(counterRes)
            const counterData = counterRes
            let counterCleanData = []
            for (let index = 0; index < counterData.length; index++) {
                let name = counterRes[index].name
                let count = parseFloat(counterRes[index].counter)
                counterCleanData.push([name, count])
            }

            setCounter(counterCleanData);
            setBateryPrice(batery_price);
            setScraping(scraping_data)
            setLoading(false)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        setDatas()
    }, [])

    if (loading) {
        return <Spinner />;
    }

    return (
        <div>
            <TitleComponent text={'Unidad administrativa'} />
            <br />
            <div className="container">
                <div className="row p-3">
                    <div className="col-sm-4 shadow-left p-3" style={{backgroundColor: 'white'}}>
                        <h4 className="subtitle">Precio de batería [USD/kWh]</h4>
                        <hr />
                        <p>$<NumberFormatter number={bateryPrice}/></p>
                    </div>
                    <div className="col-sm-4 shadow-left p-3" style={{backgroundColor: 'white'}}>
                        <h4 className="subtitle">IPC [%] <span>{customer === 'admin' ? 'CO' : customer}</span></h4>
                        <hr />
                        <p>{scraping[0].ipc}%</p>
                    </div>
                    <div className="col-sm-4 shadow-left p-3" style={{backgroundColor: 'white'}}>
                        <h4 className="subtitle">Precio del dolar [$] <span>{customer === 'admin' ? 'CO' : customer}</span></h4>
                        <hr />
                        <p>$<NumberFormatter number={scraping[0].dolar}/></p>
                    </div>
                </div>
            </div>
            <div className="container father-box">
                {/* <div className="child-box shadow-left">
                    <h4 className="subtitle">Curva de rentabilidad fija TES Colombiana</h4>
                    <LineChart
                        data={[
                            {
                                name: "Tasa",
                                data: tes[0],
                            },
                        ]}
                        labels={tes[1]}
                    />
                    <p>*Actualizado por última vez: {lastTes.slice(0, 10)} </p>
                </div> */}
                {/* <div className="child-box shadow-left">
                    <div>
                        <h4 className="subtitle">Indicadores en tiempo real</h4>
                        <br />
                        <table className="table table-striped">
                            <th className="col-7">Item</th>
                            <th className="col-3">Valor</th>
                            <th className="col-2">Región</th>
                            <tbody>
                                <tr>
                                    <td>Precio de batería [USD/kWh]</td>
                                    <td>$<NumberFormatter number={bateryPrice} /></td>
                                    <td>GLOBAL</td>
                                </tr> */}
                                {/* <tr>
                                    <td>Precio de gasolina [$/gl]</td>
                                    <td>$<NumberFormatter number={scraping[0].fuel_price_admin} /></td>
                                    <td>CO</td>
                                </tr>
                                <tr>
                                    <td>Precio del diésel [$/gl]</td>
                                    <td>$<NumberFormatter number={scraping[0].diesel_price_admin} /></td>
                                    <td>CO</td>
                                </tr>
                                <tr>
                                    <td>Precio de la energía [$/kWh]</td>
                                    <td>$<NumberFormatter number={scraping[0].energy_price_admin} /></td>
                                    <td>CO</td>
                                </tr> */}
                                {/* <tr>
                                    <td>IPC [%]</td>
                                    <td>{scraping[0].ipc}</td>
                                    <td>{customer === 'admin' ? 'CO' : customer}</td>
                                </tr>
                                <tr>
                                    <td>Precio del dolar [$]</td>
                                    <td>$<NumberFormatter number={scraping[0].dolar} /></td>
                                    <td>{customer === 'admin' ? 'CO' : customer}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> */}
            </div>
            {/* <p className="container">
                <a class="btn btn-primary m-1" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Colombia</a>
                <button class="btn btn-primary m-1" type="button" data-bs-toggle="collapse" data-bs-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2">Mexico</button>
                <button class="btn btn-primary m-1" type="button" data-bs-toggle="collapse" data-bs-target="#multiCollapseExample3" aria-expanded="false" aria-controls="multiCollapseExample3">Ver todos</button>
                <button class="btn btn-primary m-1 btn-danger" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2">Ocultar todos</button>
            </p> */}

            <div className="father-box container">
                <div className="child-box shadow-left">
                    <h4 className="subtitle">Contador de búsquedas</h4>
                    <br />
                    <div className="row">
                        {/* <div class="collapse multi-collapse col-sm-6" id="multiCollapseExample1">
                            <div class="card card-body">
                                <ColumnsChart
                                    title={'Contador Colombia'}
                                    datas={counter}
                                />
                            </div>
                        </div>
                        <br />
                        <div class="collapse multi-collapse col-sm-6" id="multiCollapseExample2">
                            <div class="card card-body">
                                <ColumnsChart
                                    title={'Contador Mexico'}
                                    datas={counter}
                                />
                            </div>
                        </div> */}
                        <br />
                        <div class="collapse multi-collapse col-sm-12 show" id="multiCollapseExample3">
                            {/* <div class="card card-body">
                                <ColumnsChart
                                    title={'Todos los conteos'}
                                    datas={counter}
                                />
                            </div> */}
                            <LineChartCounter rawData={counter2} />
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
        </div>

    );
};

export default DetailChart;