import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';

exportingInit(Highcharts);
ExportData(Highcharts);

const LineChartCounter = ({ rawData }) => {
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    const categories = rawData.map(item => item.name);
    const seriesData = rawData.reduce((acc, item) => {
      const existingSeries = acc.find(series => series.name === item.code);
      if (existingSeries) {
        existingSeries.data.push(parseInt(item.counter, 10));
      } else {
        acc.push({
          name: item.code,
          data: [parseInt(item.counter, 10)]
        });
      }
      return acc;
    }, []);

    const options = {
      lang: {
        downloadCSV: "Descarga CSV",
        viewFullscreen: "Ver en pantalla completa",
        printChart: "Imprimir Gráfica",
        downloadPNG: "Descarga PNG",
        downloadJPEG: "Descarga JPEG",
        downloadPDF: "Descarga Documento PDF",
        downloadSVG: "Descarga vector SVG",
        downloadXLS: "Descarga XLS",
        viewData: "Ver datos de tabla",
        category: 'Categoría'
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: categories,
        title: {
          text: 'Nombre del vehículo',
        },
      },
      yAxis: {
        title: {
          text: 'Contador',
        },
      },
      series: seriesData,
      tooltip: {
        valueDecimals: 0,
      },
    };

    setChartOptions(options);
  }, [rawData]);

  useEffect(() => {
    if (chartOptions) {
      const chart = Highcharts.chart('lineChartCounterContainer', chartOptions);
      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }
  }, [chartOptions]);

  return <div id="lineChartCounterContainer" style={{ height: '650px', maxWidth: '100%' }}></div>;
};

export default LineChartCounter;
