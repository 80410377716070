import axios from 'axios';
import config from '../../../config'

const domain = config.api_domain.domain;

const DeleteService = async (endpoint, id) => {
  try {
    const url = `${domain}/${endpoint}/${id}`;
    const res = await axios.delete(url, id, {
      headers: { 'Content-Type': 'application/json' },
    });
    return res.data.body;

  } catch (error) {
    console.error(error);
    return null;
  }
};

export default DeleteService;