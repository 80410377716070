import React from 'react';

const NumberFormatter = ({ number }) => {
  if (typeof number !== 'number') {
    return `Error: ${number}` 
  } else {
    parseFloat(number)
    const formatNumber = (number) => {
      return number.toLocaleString('es-ES', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
      });
    };
  
    return <>{formatNumber(number)}</>;
    // return number
  }
};

export default NumberFormatter;
