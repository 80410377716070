import React, { useState, useEffect } from "react";
import Spinner from "../../../components/spinner/Spinner";
import LoaderUtilsService from "../../../services/admin/utils/loaderUtilsService";
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import AddUtilsService from "../../../services/admin/adds/AddUtilService";
import TooltipModal from "../../../components/modals/ToolTipModal";

const ListBateryPrice = ({ permission }) => {
  const [loading, setLoading] = useState(true);
  const [bateryPrice, setBateryPrice] = useState([]);
  const [price, setPrice] = useState();
  const [showForm, setShowForm] = useState(false);

  const setDatas = async () => {
    try {
      const batery_data = await LoaderUtilsService("batery_prices");
      setBateryPrice(batery_data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };


  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const data = {
      price: price,
    };
    await AddUtilsService("insert_batery_price", data);
    setPrice("");
    setShowForm(false);
    setDatas();
  };

  useEffect(() => {
    setDatas();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    permission ? (
      <>
        <div>
          <TitleComponent text={"Lista de precios de batería"} />
          <table className="container shadow-left" style={{ background: "white" }}>
            <thead>
              <th>Fecha</th>
              <th>Precio <TooltipModal
                title={'Precio de la batería'}
                text={`Este valor corresponde al precio del kWh en el mercado. Una posible fuente de información es la compañía BloombergNEF, la cual ha establecido un costo para pack de baterías en el mercado desde el año 2013. Este valor se ingresa una única vez por año, no es modificable y aplica para todos los paises`}
              /> </th>
            </thead>
            <tbody>
              {bateryPrice.map((data, index) => (
                <tr key={index}>
                  <td>{data.date_time}</td>
                  <td> USD {data.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="container">
            <br />
            <strong>      <p>Consulta el precio aquí: <a target="_blank" href="https://about.bnef.com/blog/lithium-ion-battery-pack-prices-rise-for-first-time-to-an-average-of-151-kwh/">BloombergNEF</a></p></strong>
            <br />
            <button onClick={() => setShowForm(!showForm)}>Nuevo</button>
            {showForm && (
              <form onSubmit={handleFormSubmit}>
                <br /><br />
                <label>Nuevo precio </label>
                <input
                  type="number"
                  className='form-control'
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
                <button type="submit">Guardar</button>
              </form>
            )}
          </div>
        </div>
      </>
    ) : (
      <>Sin permisos de Super admin</>
    )
  );
};

export default ListBateryPrice;
