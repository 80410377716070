import React from 'react';
import { useState, useEffect } from 'react';
// import NewVehicle from '../../../services/admin/newVehicleService';
import LoaderUtilsService from '../../../services/admin/utils/loaderUtilsService';
import LoaderUtilsService2 from '../../../services/admin/utils/loaderUtilsService2';
import UpperFormatt from '../../../components/UpperFormatt';
import AddUtilsService from '../../../services/admin/adds/AddUtilService';
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";

const New = () => {
    const customer = localStorage.getItem('region')
    const [name, setName] = useState('Generic Vehicle')
    const [fuel, setFuel] = useState('Eléctrico')
    const [type, setType] = useState('camion')
    const [code, setCode] = useState(32)
    const [country, setCountry] = useState(1)

    const [selectedClass, setSelectedClass] = useState('');
    const [selectedCode, setSelectedCode] = useState('');
    const [soat, setSoat] = useState([])
    const [categories, setCategories] = useState([])
    const [createdDone, setCreatedDone] = useState(false)
    const [countries, setCountries] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await LoaderUtilsService('soat');
                const dataCategories = await LoaderUtilsService('categories');
                const dataCountries = await LoaderUtilsService2('countries');

                setSoat(data);
                setCategories(dataCategories)
                setCountries(dataCountries)
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const handleSendInfo = async () => {

        try {
            const data = {
                "name": name,
                "fuel": fuel,
                "type": type,
                "code": code,
                "country": country
            }

            await AddUtilsService('addVehicle', data)

            console.log('Vehículo Creado con', data)
            setCreatedDone(true)
        } catch (error) {
            console.error(error);
        }
    };

    const handleNew = () => {
        setName()
        setFuel()
        setType()
        setSelectedClass()
        setSelectedCode()
        setCreatedDone(false)
    }


    const handleName = (e) => { setName(e.target.value) }
    const handleFuel = (e) => { setFuel(e.target.value) }
    const handleType = (e) => { setType(e.target.value) }
    const handleCountry = (e) => { setCountry(e.target.value) }

    const getCodesByClass = () => {
        if (selectedClass === '') {
            return [];
        }
        return soat
            .filter(item => item.class === selectedClass)
            .map(item => ({ code: item.code, code_description: item.code_description }));
    };


    const handleClassChange = async (event) => {
        setSelectedClass(event.target.value);
        setSelectedCode('');
    };

    const handleCodeChange = (event) => {
        setSelectedCode(event.target.value);
        setCode(event.target.value)
    };

    const uniqueClasses = Array.from(new Set(soat.map(item => item.class)));
    const uniqueCategories = Array.from(new Set(categories.map(item => item.category)));

    const handleSendData = () => {
        Swal.fire({
          title: '¿Estás seguro?',
          text: 'Se creará un nuevo vehículo. ¿Deseas continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Crear',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#0064C8',
          cancelButtonColor: '#d33',
        }).then((result) => {
          if (result.isConfirmed) {
            handleSendInfo();
          }
        });
      }

    return (
        <>
            <h2 className='titleComponent'>Nuevo vehículo</h2>
            <div className='container'>
                {createdDone === false ?
                    <div>            <div className='col-sm-6'>
                        <label className='modal-lavel'>Nombre del vehículo
                        </label>
                        <input type="text" id="name" className='form-control' onChange={handleName} required />
                        <br />
                        <label className='modal-lavel'>Tipo de combustible
                        </label>
                        <select className='form-select' id='fuelSelect' onChange={handleFuel} required>
                            <option selected>Selecciona tipo de combustible</option>
                            <option value="Eléctrico">Eléctrico</option>
                            <option value="Diesel">Diesel</option>
                            <option value="Gasolina">Gasolina</option>
                        </select>
                        <br />
                        <label className='modal-lavel'>Categoría del vehículo
                        </label>
                        <select className='form-select' id='typeSelect' onChange={handleType} required>
                            <option selected>Selecciona una categoría</option>
                            {uniqueCategories.map((cat, index) => (
                                <option key={index} value={cat}><UpperFormatt string={cat} /></option>
                            ))}
                        </select>
                        <br />
                        <label className='modal-lavel'>País del vehículo
                        </label>
                        <select className='form-select' id='typeSelect' onChange={handleCountry} required>
                            <option selected>Selecciona un país</option>
                            {countries.filter(item => item.code !== 'admin').map((c, index) => (
                                <option key={index} value={c.id}>{c.country_name}</option>
                            ))}
                        </select>
                        <br />
                        {customer === 'CO' || customer === 'admin' ? (
                            <>
                                                                                <label className='modal-lavel'>Código de aseguramiento
                                                    </label>
                                                    <div>
                                                        <select className='form-select' id="classSelect" value={selectedClass} onChange={handleClassChange}>
                                                            <option selected>Seleccione una clase</option>
                                                            {uniqueClasses.map((classItem) => {
                                                                const selectedClassItem = soat.find(item => item.class === classItem);
                                                                return (
                                                                    <option key={selectedClassItem.id} value={selectedClassItem.class}>
                                                                        {selectedClassItem.class} - {selectedClassItem.class_description}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <br />
                                                        <br />
                                                        {selectedClass !== '' && (
                                                            <div>
                                                                <select className='form-select' id="codeSelect" value={selectedCode} onChange={handleCodeChange}>
                                                                    <option value="" selected>Seleccione un código</option>
                                                                    {getCodesByClass().map((codeItem, index) => (
                                                                        <option key={index} value={codeItem.code}>
                                                                            {codeItem.code} - {codeItem.code_description}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <br />
                            </>) : (<></>)}
                        <button onClick={handleSendData} >Crear vehículo</button>
                    </div>
                    </div>
                    :
                    <div>
                        <button onClick={handleNew}>Crear otro</button>
                        <br /><br />
                        <NavLink to={`/admin/list`}>
                            <button>Ir a la lista</button>
                        </NavLink>
                    </div>
                }
                <br /><br />
            </div>
        </>
    )
}

export default New;