import React from 'react'
import NumberFormatter from '../../../components/Formarts';
import BarChart from '../../../components/charts/BarChart';
import TooltipModal from '../../../components/modals/ToolTipModal';


const TableProductivity = ({ data }) => {

  const customization = JSON.parse(localStorage.getItem('customizations'))
  const volumen = customization[0].custom[0].volumen
  const norma = customization[0].custom[0].norma_no_circula

  // const currency = localStorage.getItem("currency")

  if (!data || data.length <= 0 || data.error || data === null || data == []) {
    return <p className="subtitle">Sin parámetros asignados</p>;
  }

  return (
    <div>{data.length > 0 && (
      <>
        <div className="father-box">
          <div className="child-box shadow-left">
            <div className="title">
              <h4 className='subtitle'>Valores de vehículo eléctrico</h4>
              <br />
            </div>

            <div className="father-box">
              <div className="child-sm-box">
                <h4 className='subtitle'>Recorrido de batería</h4>
                <table className='table table-striped-columns p-2'>
                  <thead>
                    <tr>
                      <td className='table-head'>Concepto</td>
                      <td className='table-head'>Valor</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Días disponibles <TooltipModal text={'Cantidad de días disponibles al año de acuerdo al rango de operación seleccionado en la ventana de parámetros'} title={'Días disponibles'} /></td>
                      <td><span className='result-text'>{data[0]['aviabilityDays']}</span></td>
                    </tr>
                    <tr>
                      <td>Recorrido de carga completa [km] <TooltipModal text={'Recorrido que puede hacer un ciclo completo de descarga de la batería, del 100% al 20%'} title={'Recorrido de carga completa [km]'} /></td>
                      <td><span className='result-text'><NumberFormatter number={data[0]['fullTour']}/></span></td>
                    </tr>
                    <tr>
                      <td>Cantidad de ciclos de carga al año <TooltipModal text={'Cantidad de ciclos de carga que se deben realizar para cumplir el uso anual proyectado en la ventana de parámetros'} title={'Cantidad de ciclos de carga al año'} /></td>
                      <td><span className='result-text'>{data[0]['chargesPerYear']}</span></td>
                    </tr>
                    <tr>
                      <td>Recorrido diario [km] <TooltipModal text={'Es el recorrido diario que se debe realizar para cumplir el uso anual proyectado en la ventana de parámetros, con base en los días disponibles de operación'} title={'Recorrido diario [km]'} /></td>
                      <td><span className='result-text'><NumberFormatter number={data[0]['dailyTour']} /></span></td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h4 className='subtitle'>Tiempos no operativos</h4>
                <table className="table table-striped-columns p-2">
                  <thead>
                    <tr>
                      <td className='table-head'>Concepto</td>
                      <td className='table-head'>Valor</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Tiempo extra de carga al día [h] <TooltipModal text={'Tiempo que se debe tomar la batería para cargar al día, con el objetivo de cumplir con el recorrido diario mínimo'} title={'Tiempo extra de carga al día [h]'} /></td>
                      <td><span className='result-text'><NumberFormatter number={data[0]['extraTime']} /></span> </td>
                    </tr>
                    <tr>
                      <td>Pérdidas por ciclo de carga del vehículo [$] <TooltipModal text={'Valor de pérdidas por tiempos no operativos en cada ciclo de carga del vehículo'} title={'Pérdidas por ciclo de carga del vehículo [$]'} /></td>
                      <td><span className='result-text'>$ <NumberFormatter number={data[0]['lossCharge']} /></span></td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h4 className='subtitle'>Disponibilidad diaria <TooltipModal text={'Tiempos operativos fuera de los procesos de carga del vehículo'} title={'Disponibilidad diaria'} /></h4>
                <table className="table table-striped-columns p-2">
                  <thead>
                    <tr>
                      <td className='table-head'>Concepto</td>
                      <td className='table-head'>Valor</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Con carga lenta [h]</td>
                      <td><span className='result-text'><NumberFormatter number={data[0]['aviabilityChargeSlow']} /></span></td>
                    </tr>
                    <tr>
                      <td>Porcentaje de disponiblidad diaria carga lenta [%]</td>
                      <td><span className='result-text'><NumberFormatter number={data[0]['aviabilityPercentageSlow'] * 100} /></span></td>
                    </tr>
                    <tr>
                      <td>Con carga rápida [h]</td>
                      <td><span className='result-text'>{data[0]['charge_time_2'] === null ? 'N/A' : <NumberFormatter number={data[0]['aviabilityChargeFast']} />}</span></td>
                    </tr>
                    <tr>
                      <td>Porcentaje de disponiblidad diaria carga rápida [%]</td>
                      <td><span className='result-text'>{data[0]['charge_time_2'] === null ? 'N/A' : <NumberFormatter number={data[0]['aviabilityPercentageFast'] * 100} />}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="father-box">
              <div className="child-sm-box">
                <h5>PROYECCIÓN DE NIVEL DE PÉRDIDAS DEL VEHÍCULO ELÉCTRICO</h5>
                <br />
                <table className="table table-striped-columns p-2">
                  <tbody>
                    <tr>
                      <td className="table-head">Año</td>
                      <td className="table-head">Valor presente <TooltipModal text={'Corresponde al Valor Presente Neto de cada uno de los años en ejecución, el cual permite establecer un costo total a día de hoy en una proyección financiera'} title={'Valor presente'} /></td>
                    </tr>
                    {data[0].annualLoss.presentValue.map((datas, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>$ <NumberFormatter number={datas} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <h4 className="big-result-text" >Total en pérdidas: $ <NumberFormatter number={data[0]['annualLoss']['total']} /></h4>
          </div>

          <div className="child-box shadow-left">
            <div className="title">
              <h4 className='subtitle'>Valores de vehículo a combustión</h4>
              <br />
            </div>
            <div className="father-box">
              <div className="child-sm-box">
                <h4 className='subtitle'>Parámetros generales</h4>
                <table className="table table-striped-columns p-2">
                  <thead>
                    <tr>
                      <td className='table-head'>Concepto</td>
                      <td className='table-head'>Valor</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Consumo de energía [kWh/km]</td>
                      <td><span className='result-text'><NumberFormatter number={data[0]['combustionConsumption']} /></span></td>
                    </tr>
                    <tr>
                      <td>Rendimiento de combustible [km/{volumen}]</td>
                      <td><span className='result-text'><NumberFormatter number={(data[0]['fuelEfficiency'])} /></span></td>
                    </tr>
                    <tr>
                      <td>Recorrido de tanque lleno [km] <TooltipModal text={'Autonomía nominal del vehículo'} title={'Recorrido de tanque lleno [km]'} /></td>
                      <td><span className='result-text'><NumberFormatter number={data[0]['tourFullTank']} /></span> </td>
                    </tr>
                    <tr>
                      <td>Número de tanqueadas completas al año <TooltipModal text={'Cantidad de tanqueadas por año para cumplir con el uso anual proyectado en la ventana de parámetros'} title={'Número de tanqueadas completas al año'} /></td>
                      <td><span className='result-text'>{data[0]['tanksAmount']}</span> </td>
                    </tr>
                    <tr>
                      <td>Duración del tanque completo [días] <TooltipModal text={'Cantidad de días con combustible con base en el recorrido diario mínimo'} title={'Duración del tanque completo [días]'} /></td>
                      <td><span className='result-text'><NumberFormatter number={data[0]['tankDaysLife']} /></span> </td>
                    </tr>
                    <tr>
                      <td>Tanqueo de combustible diario [{volumen}] <TooltipModal text={'Cantidad de combustible al día que se debe ingresar al vehículo para evitar tiempos no operativos'} title={`Tanqueo de combustible diario [${volumen}]`} /></td>
                      <td><span className='result-text'><NumberFormatter number={(data[0]['daylyTanking'])} /></span> </td>
                    </tr>
                  </tbody>
                </table>
                    <br />
                    <h4 className='subtitle'>Tiempos no operativos</h4>
                <table className="table table-striped-columns p-2">
                    <thead>
                      <tr>
                        <td className='table-head'>Concepto</td>
                        <td className='table-head'>Valor</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Tiempo de llenado del tanque [h] <TooltipModal text={'Tiempo que dura un ciclo completo de llenado del tanque de combustible'} title={'Tiempo de llenado del tanque [h]'} /></td>
                        <td><span className='result-text'><NumberFormatter number={data[0]['tankingTime']} /></span> </td>
                      </tr>
                      <tr>
                        <td>Tiempo disponible de operación [h] <TooltipModal text={'Tiempo al día disponible exceptuando el tiempo de llenado del tanque'} title={'Tiempo disponible de operación [h]'} /></td>
                        <td><span className='result-text'><NumberFormatter number={data[0]['aviabilityTime']} /></span> </td>
                      </tr>
                      <tr>
                        <td>Porcentaje disponible de operación [%]</td>
                        <td><span className='result-text'><NumberFormatter number={data[0]['fuelAviabilityPercentage'] * 100} /></span></td>
                      </tr>
                      <tr>
                        <td>Tiempo de tanqueo diario [h] <TooltipModal text={'Tiempo en que toma el vehículo hacer el tanqueo de combustible diario'} title={'Tiempo de tanqueo diario [h]'} /></td>
                        <td><span className='result-text'><NumberFormatter number={data[0]['daylyTankingTime']} /></span> </td>
                      </tr>
                      <tr>
                        <td>Tiempo de {norma} [h] <TooltipModal text={`Tiempo no operativo al día por penalización de ${norma}, con base en las normativas vigentes de cada ciudad`} title={`Tiempo de ${norma} [h]`} /></td>
                        <td><span className='result-text'><NumberFormatter number={data[0]['ppTime']} /></span> </td>
                      </tr>
                      <tr>
                        <td>Pérdidas por tiempos no operativos al día [$]</td>
                        <td><span className='result-text'>$ <NumberFormatter number={data[0]['lossPerDay']} /></span></td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            <div className="father-box">
              <div className="child-sm-box">
                <h5>PROYECCIÓN DE NIVEL DE PÉRDIDAS DEL VEHÍCULO A COMBUSTIÓN</h5>
                <br />
                <table className="table table-striped-columns p-2">
                  <tbody>
                    <tr>
                      <td className="table-head">Año</td>
                      <td className="table-head">Valor presente <TooltipModal text={'Corresponde al Valor Presente Neto de cada uno de los años en ejecución, el cual permite establecer un costo total a día de hoy en una proyección financiera'} title={'Valor presente'} /></td>
                    </tr>
                    {data[0].fuelAnnualLoss.proyectedValue.map((datas, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>$ <NumberFormatter number={datas} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <br />
            <h4 className="big-result-text" >Total en pérdidas: $ <NumberFormatter number={data[0]['fuelAnnualLoss']['total']} /></h4>
          </div>
        </div>
        <br />
        <div className="shadow-left">
          <BarChart data={{
            categories: ['Productividad'],
            series: [
              {
                name: 'Eléctrico',
                data: [parseFloat(data[0]['annualLoss']['total'])],
              },
              {
                name: 'Combustión',
                data: [parseFloat(data[0]['fuelAnnualLoss']['total'])],
              }
            ],
          }} />
        </div>
      </>
    )}
      <br /><br />
    </div>
  )
}

export default TableProductivity;